//
// Scaffolding
// --------------------------------------------------

// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
    box-sizing: border-box;
}
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    &[lang^="ar"] {
        direction: rtl;
    }
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
}

body {
    @include font-size(document);
    @include line-height(document);
    @include primary-font;

    color: $text-color;
    background-color: #f3f3f3;
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
}

#app {
    margin: 0 auto;
}

p {
    margin-top: 0;
    margin-bottom: 1.75rem;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* clears Safari autocompletion icons */
input::-webkit-credentials-auto-fill-button {
    position: absolute;
    width: 1px;
    height: 1px;
    background-color: transparent;
    visibility: hidden;
}

// Reset fonts for relevant elements

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// Links

a {
    color: #9b9b9b;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
    margin: 0;
}

// Images

img {
    max-width: 100%;
    vertical-align: middle;
}

// Horizontal rules

hr {
    margin-top: box-model(l);
    margin-bottom: box-model(l);
    border: 0;
    border-top: 1px solid $hr-border;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
    }
}

.clearfix {
    @include clearfix;
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
    @include font-size(s);
}

// Alignment
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-justify {
    text-align: justify;
}
.text-nowrap {
    white-space: nowrap;
}

// Transformation
.text-lowercase {
    text-transform: lowercase;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}

// Contextual colors
.text-muted {
    color: $text-muted;
}

// Forms
// ---------------------
.form-description {
    margin: box-model(0, 0, l);
    display: block;
}

.form-group {
    margin: box-model(0, 0, l);
}

label {
    font-size: 0.8rem;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.4rem 0;
    font-size: 1rem;
    line-height: 1.5;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-bottom: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 40px;

    &.is-invalid {
        border-color: #dc3545;
    }

    &.is-invalid ~ .invalid-feedback {
        display: block;
    }

    &:focus {
        outline: none;
    }
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-check {
    label {
        font-weight: normal;
        cursor: pointer;

        span {
            display: block;
            margin-left: 30px;
        }

        input[type="checkbox"],
        input[type="radio"] {
            float: left;
        }
    }
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    color: #dc3545;
}

.is-invalid > .invalid-feedback {
    display: block;
}

.form-actions {
    @include clearfix;
}

// Buttons
// ------------------------
.btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: opacity 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-overflow: ellipsis;
    overflow: hidden;

    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &:hover {
        text-decoration: none;
    }

    &.disabled,
    &:disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }

    &.btn-block {
        max-width: 280px;
        margin: 0 auto 10px;
    }

    &.btn-tertiary,
    &.back {
        background-color: transparent;
        color: #9b9b9b;
        border-color: transparent;
    }

    &.back {
        padding-left: 0;
        padding-right: 0;
    }
}

// Application
// -----------------------
$card-paddings: 20px;
$container-max-width: 1440px;
$borders-color: #efefef;
$card-max-width: 570px;
$login-card-padding: box-model(xl);

#app.ready {
    .card-panel {
        display: block;
    }
}

.container {
    max-width: $container-max-width;
    margin: 0 auto;
}

%card {
    max-width: $card-max-width;
    margin: 0 auto;
}

.card-panel {
    @extend %card;
    display: none;
    position: relative;
    padding: $login-card-padding;

    &.with-banner {
        padding-top: 85px;
    }

    h1,
    h2 {
        text-align: center;
        font-weight: 700;
    }

    .webinar-info {
        margin-top: $base-margin;
    }
}

.banner {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px 10px 0 0;

    img {
        width: inherit;
        border-radius: inherit;
    }
}

.outer-box {
    display: flex;

    > * {
        flex: 1 1 100%;

        &.logins {
            max-width: 250px;
        }
    }
}

.intros {
    border-bottom: 1px solid $borders-color;
    padding: 0 $card-paddings $card-paddings;
    margin-left: $card-paddings * -1;
    margin-right: $card-paddings * -1;
    margin-bottom: $card-paddings;
}

.logins {
    margin: 0 auto;

    .btn {
        width: 100%;
        margin-bottom: 10px;
    }
}

.service-links {
    margin: 10px -5px;
    text-align: right;

    a {
        display: inline-block;
        padding: 0 5px;
        cursor: pointer;
    }
}

.error {
    @extend %card;
    border-radius: 10px;
    background-color: lighten(red, 40%);
    border-color: lighten(red, 25%);
    display: none;
    padding: 30px 40px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    text-align: center;
    &.callback {
        padding: 8px;
        padding-bottom: 24px;
        margin-top: 4px;
    }
    &.in {
        display: block;
    }

    p {
        margin: 0;
    }

    &.reason {
        display: block;
        margin-bottom: 20px;
    }
}

.search-toggler {
    float: right;
    vertical-align: middle;
    line-height: 1rem;
    padding: 6px;
    font-size: 1.25rem;
    margin: 0 6px;
}

.account {
    margin: 0 0 0 6px;
    position: relative;

    i.icon-user {
        position: relative;
        cursor: pointer;
        z-index: 5;
        display: inline-block;
        line-height: 1;
        font-size: 1.25rem;
        padding: 6px;
    }
}

.menu {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 4px;
    border: 1px solid $borders-color;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
    line-height: 1;
    list-style-type: none;
    margin: 40px 0 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 0;
    transform: translateY(-200%);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &.in {
        opacity: 1;
        transform: translateY(0);
        z-index: 10;
    }

    li a,
    li span.email {
        display: block;
        padding: 10px 15px;
        min-width: 200px;
    }

    a {
        cursor: pointer;
    }
}

.events {
    display: flex;
    flex-wrap: wrap;
    margin: -10px math.div($card-paddings, -2) 20px;
}

.event {
    flex: 0 0 33.33%;
    cursor: pointer;
    padding: 10px;

    &.past {
        .evt-icon:before {
            position: absolute;
            content: "";
            display: block;
            background-color: rgba(155, 155, 155, 0.5);
            height: 100%;
            width: 100%;
        }

        .icon-time {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 36px;
            margin-top: -18px;
            margin-left: -18px;
        }
    }

    $evt-icon-size: 130px;

    .inner {
        height: $evt-icon-size;
        overflow: hidden;
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        position: relative;
        box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;

        &:hover {
            transform: scale(1.02);
        }
    }

    .evt-icon {
        width: $evt-icon-size;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;

        &:before,
        .icon {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .icon {
            background-size: contain;
            position: absolute;
            top: 0;
            left: 0;
            background-image: image-url("event_placeholder.png");
        }
    }

    .details {
        margin-left: $evt-icon-size;
    }

    $event-title-height: 44px;

    .name {
        height: $event-title-height;
        max-height: $event-title-height;
        overflow: hidden;
    }

    span {
        display: block;

        &.text {
            display: inline;
        }

        &.title {
            height: $event-title-height;
            margin-bottom: 10px;
            display: table-cell;
            line-height: 1.4;
            vertical-align: middle;
            font-weight: bold;
        }

        &.dates,
        &.venue {
            font-size: 0.75rem;
            line-height: 2;
        }

        i {
            margin-right: 10px;
        }
    }
}

.legals {
    padding: $card-paddings;
    max-height: 100%;
    overflow: auto;
}

.legals,
.legal-acknowledgements {
    a {
        color: #000;
        font-weight: bold;
    }
}

.back {
    float: left;
}

.invitations {
    padding-top: 125px;

    h5 {
        margin: 24px 0 16px;
        font-weight: normal;
        color: #9b9b9b;
    }

    .empty {
        margin-top: 24px;
        text-align: center;

        svg {
            max-width: 348px;
        }

        p {
            margin-top: 24px;
            font-weight: bold;
        }
    }
}

header.main {
    background-color: #fff;
    box-shadow: 0 -10px 20px 10px rgba(0, 0, 0, 0.2);
    padding: 35px 0;
    color: #9b9b9b;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 20;

    .container > * {
        display: inline-block;
        vertical-align: middle;

        &.search-toggler {
            display: none;
        }
    }

    h1 {
        margin: 0;
        color: #4a4a4a;
    }

    .account {
        float: right;
    }
}

.install {
    text-align: center;

    .frame {
        display: block;
        width: 240px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: 10px auto;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        &.in {
            opacity: 1;
        }

        &.out {
            display: none;
        }

        img {
            width: 130px;
            margin: 36px;
        }
    }

    .install-button {
        display: block;
        max-width: 250px;
        margin: 10px auto;
    }

    .badges {
        display: inline-block;

        a {
            display: inline-block;
            max-width: 135px;
            margin: 0 20px;

            &.app-store-link img {
                margin: 6%;
                width: 88%;
            }
        }
    }
}

.pass-group .form-group {
    position: relative;
}

.password-score {
    position: absolute;
    right: 10px;
    top: 30px;
    font-weight: bold;

    .score-tab {
        &[data-score="0"] {
            color: #d20e26;
        }
        &[data-score="1"] {
            color: #d20e26;
        }
        &[data-score="2"] {
            color: #f6af3a;
        }
        &[data-score="3"] {
            color: #80d325;
        }
        &[data-score="4"] {
            color: #80d325;
        }
    }
}

.login-methods {
    text-align: center;

    .btn {
        display: block;
        margin-bottom: 20px;
    }
}

.email-sent {
    text-align: center;
}

.welcome {
    text-align: center;

    h2 {
        margin-top: 0;
    }

    img.splash {
        max-width: 228px;
        margin: 50px 0;
    }

    .btn {
        margin-bottom: 30px;
    }
}

ul.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        cursor: pointer;
        border-radius: 4px;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        padding: 7px 12px;
        margin: 0 5px;
        line-height: 1;
    }
}

.search-bar {
    margin: 0 20px;

    .form-group {
        margin: 0;
        position: relative;
    }

    input {
        border: 0;
        background-color: #f3f3f3;
        padding: 10px;
        border-radius: 4px;
        min-width: 270px;

        &:focus + i.icon-search {
            opacity: 0;
        }
    }

    i.ic-search {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        display: block;
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        opacity: 1;
        transition: opacity 0.3s ease-in-out 0.5s;
    }

    .clear {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 36px;
        font-size: 24px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: 2;

        &.in {
            opacity: 1;
            cursor: pointer;
        }
    }
}

footer.links {
    @include clearfix;

    margin-top: 15px;
    text-align: center;

    a {
        color: #9b9b9b;
    }

    span {
        color: #9b9b9b;
        padding-left: 3px;
        padding-right: 3px;
    }
}

@media (max-width: 991.98px) {
    h1 {
        font-size: 1.25rem;
    }

    .container {
        max-width: 738px;
    }

    header.main {
        padding: 30px 0 24px;

        .container > {
            .search-toggler,
            .account {
                display: inline-block;
            }

            .search-bar {
                display: block;
                height: 0;
                overflow: hidden;
                margin: 0;
                clear: both;
                transition: height 0.2s ease-in-out, margin 0.2s ease-in-out;

                &.in {
                    height: 40px;
                    margin-bottom: 24px;
                    margin-top: 24px;
                }

                .icon-search {
                    display: none;
                }
            }

            ul.tabs {
                display: block;
                margin-top: 10px;
                margin-left: -5px;
            }
        }
    }

    .invitations {
        padding-top: 129px;
    }

    .event {
        .inner {
            padding: 10px;
        }

        .evt-icon {
            width: 108px;
        }

        .details {
            margin-left: 108px;
        }
    }
}

@media (max-width: 767px) {
    body {
        background-color: #fff;
        padding-top: 0;
    }

    .container {
        max-width: none;
        padding-left: 15px;
        padding-right: 15px;
    }

    .event {
        flex: 0 0 100%;
    }

    .card-panel {
        padding: box-model(m);

        &.with-banner {
            padding-top: 65px;
        }
    }
}

@media (min-width: 768px) {
    .event {
        flex: 0 0 50%;
    }

    .card-panel {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 952px;
    }

    .search-bar input {
        min-width: 200px;
    }

    .account {
        margin-top: 6px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1200px;
    }

    .event {
        flex: 0 0 33.33%;
    }

    .search-bar input {
        min-width: 270px;
    }
}

.public-login .expandable-text {
    font-size: 14px;
    line-height: 22px;
    color: #18191a;
    margin: 12px 0 24px;
    max-width: calc(#{$card-max-width} - 2 * #{$login-card-padding});

    .read-more {
        text-align: right;
    }

    .text {
        overflow: hidden;
        overflow-wrap: break-word;

        // Quill HTML editor text alignment classes

        @each $alignment in center, left, right, justify {
            .ql-align-#{$alignment} {
                text-align: $alignment;
            }
        }

        // Quill HTML editor lists (ul and ol) and text indentation classes

        $LIST_COUNTERS: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        $LIST_STYLE: decimal lower-alpha lower-roman;
        $LIST_STYLE_WIDTH: 1.2em;
        $LIST_STYLE_MARGIN: 0.3em;
        $LIST_STYLE_OUTER_WIDTH: $LIST_STYLE_MARGIN + $LIST_STYLE_WIDTH;
        $MAX_INDENT: 9;

        @for $num from 1 through $MAX_INDENT {
            .ql-indent-#{$num}:not(.ql-direction-rtl) {
                padding-left: $num * 3em;
            }

            li.ql-indent-#{$num}:not(.ql-direction-rtl) {
                padding-left: ($num * 3em) + $LIST_STYLE_OUTER_WIDTH;
            }

            .ql-indent-#{$num}.ql-direction-rtl.ql-align-right {
                padding-right: $num * 3em;
            }

            li.ql-indent-#{$num}.ql-direction-rtl.ql-align-right {
                padding-right: ($num * 3em) + $LIST_STYLE_OUTER_WIDTH;
            }
        }

        ol.ql-list {
            list-style-type: none;

            li {
                counter-reset: $LIST_COUNTERS;
                counter-increment: list-0;

                &::before {
                    content: counter(list-0, decimal) ". ";
                }

                @for $num from 1 through $MAX_INDENT {
                    &.ql-indent-#{$num} {
                        counter-increment: list-#{$num};

                        &::before {
                            content: unquote("counter(list-#{$num}, #{ nth($LIST_STYLE, ($num % 3) + 1) })") ". ";
                        }
                    }

                    @if $num < $MAX_INDENT {
                        &.ql-indent-#{$num} {
                            counter-reset: slice($LIST_COUNTERS, $num + 1, $MAX_INDENT);
                        }
                    }
                }
            }
        }

        .ql-list {
            list-style-type: none;

            > li::before {
                content: "\2022";
                display: inline-block;
                white-space: nowrap;
                width: 1.2em;
            }

            li {
                &:not(.ql-direction-rtl)::before {
                    margin-left: -1.5em;
                    margin-right: 0.3em;
                    text-align: right;
                }
            }
        }

        blockquote {
            padding: 10px 20px;
            margin: 0 0 20px;
            border-left: 5px solid #eeeeee;
        }
    }
}

.webinar-response .calendar-button {
    .btn {
        margin-top: 20px;
    }

    .menu {
        right: calc(50% + 36px);
    }
}

.calendar-button {
    position: relative;

    button.btn {
        height: 36px;
        border-radius: 8px;
        padding: 8px;
        border: 0;
        background-color: #f7f7f7;
        color: #343a40;
        text-align: center;
        font-size: 14px;
        margin: auto;

        display: flex;
        flex-direction: row;
        align-items: flex-start;


        &:hover,
        &.in {
            background-color: #dddddd;
            color: #343a40;
        }

        i {
            font-size: 20px;
            vertical-align: top;
            margin-right: 8px;
        }

        &.single {
            i { margin-right: 0 }
        }

        span {
            margin-left: $base-unit;
        }
    }

    .menu {
        display: block;
        margin: 40px -170px 0;
        transform: none;
        border-radius: 8px;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        &.fade-enter-active, &.fade-leave-active {
            opacity: 1;
        }

        &.fade-enter, &.fade-leave-to {
            opacity: 0;
        }

        li {
            min-width: 200px;

            a {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 8px 4px;

                &:focus,
                &:hover {
                    text-decoration: none;
                }

                &:hover {
                    background: #f3f3f3;
                }

                i {
                    width: 24px;
                    height: 24px;
                    margin: 3px 0 0 6px;

                    &.icon-cal {
                        background-image: image-url("generic_calendar.svg");
                    }

                    &.icon-gcal {
                        background-image: image-url("google_cal.svg");
                    }
                }

                span {
                    color: black;
                    font-size: 14px;
                    padding: 8px;
                }
            }
        }
    }
}

.webinar-info {
    display: flex;
    padding: 16px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 #00000014;

    .icon > img {
        width: 64px;
        height: 64px;
        margin-right: 16px;
        border-radius: 8px;
    }

    .meta {
        display: flex;
        justify-content: space-between;
        flex: 1;

        .info {
            font-weight: 600;

            .title {
                font-size: 20px;
                line-height: 32px;
                color: #18191a;
            }

            .datetime {
                font-size: 14px;
                line-height: 20px;
                color: #7d8082;

                .timezone {
                    font-weight: 400;
                    margin-left: 5px;
                }
            }
        }
    }
}


.v--modal-overlay .v--modal-box {
    transform: translateY(-50%);

    // Following !importants due to inline styles
    top: 50% !important;
    position: absolute;
    max-height: 75vh; // Do not raise this value or iOS will cut content
    overflow: auto;
}
