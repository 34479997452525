//== Scaffolding
$base-margin: 16px;

// Colors
$gray-base: #2c3e50;
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee
$brand-primary: #fb4f15;
$hr-border: $gray-lighter;
//** Global text color on `<body>`.
$text-color: #2c3e50;
$text-muted: $gray-light;

//** Global textual link color.
$link-color: $brand-primary;

//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);

//** Link hover decoration.
$link-hover-decoration: underline;
