@charset "UTF-8";
@font-face {
  font-family: "fonticon";
  src: url("../fonts/fonticon.woff"), url("../fonts/fonticon.ttf"), url("../fonts/fonticon.svg");
  font-weight: "fonticon.eot";
  font-style: normal;
}
[class^=ic-], [class*=" ic-"], .glyphicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "fonticon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-loader {
  display: inline-block;
}

.ic-volume-2:before {
  content: "\e942";
}

.ic-compass:before {
  content: "\e9e4";
}

.ic-user:before {
  content: "\e9e5";
}

.ic-trending-up:before {
  content: "\e905";
}

.ic-heart:before {
  content: "\e906";
}

.ic-align-left:before {
  content: "\e907";
}

.ic-layout:before {
  content: "\e908";
}

.ic-moon:before {
  content: "\e909";
}

.ic-film:before {
  content: "\e90a";
}

.ic-chevron-left:before {
  content: "\e90b";
}

.ic-navigation-2:before {
  content: "\e90c";
}

.ic-minus:before {
  content: "\e90d";
}

.ic-monitor:before {
  content: "\e90e";
}

.ic-corner-down-left:before {
  content: "\e90f";
}

.ic-users:before {
  content: "\e910";
}

.ic-folder:before {
  content: "\e911";
}

.ic-chrome:before {
  content: "\e912";
}

.ic-menu:before {
  content: "\e913";
}

.ic-alert-octagon:before {
  content: "\e914";
}

.ic-tablet:before {
  content: "\e915";
}

.ic-user-check:before {
  content: "\e916";
}

.ic-alert-triangle:before {
  content: "\e917";
}

.ic-bookmark:before {
  content: "\e918";
}

.ic-pause:before {
  content: "\e919";
}

.ic-trending-down:before {
  content: "\e91a";
}

.ic-upload:before {
  content: "\e91b";
}

.ic-rewind:before {
  content: "\e91c";
}

.ic-shuffle:before {
  content: "\e91d";
}

.ic-download-cloud:before {
  content: "\e91e";
}

.ic-cloud:before {
  content: "\e91f";
}

.ic-slack:before {
  content: "\e920";
}

.ic-volume-x:before {
  content: "\e921";
}

.ic-arrow-down-right:before {
  content: "\e922";
}

.ic-minus-circle:before {
  content: "\e923";
}

.ic-align-center:before {
  content: "\e924";
}

.ic-arrow-left:before, .glyphicon-arrow-left:before {
  content: "\e925";
}

.ic-globe:before {
  content: "\e926";
}

.ic-chevrons-left:before {
  content: "\e927";
}

.ic-phone-incoming:before {
  content: "\e928";
}

.ic-filter:before {
  content: "\e929";
}

.ic-map-pin:before {
  content: "\e92a";
}

.ic-twitter:before {
  content: "\e92b";
}

.ic-activity:before {
  content: "\e92c";
}

.ic-video:before {
  content: "\e92d";
}

.ic-bell-off:before {
  content: "\e92e";
}

.ic-arrow-up:before {
  content: "\e92f";
}

.ic-share:before {
  content: "\e930";
}

.ic-mic-off:before {
  content: "\e931";
}

.ic-more-vertical:before {
  content: "\e932";
}

.ic-zap:before {
  content: "\e933";
}

.ic-external-link:before {
  content: "\e934";
}

.ic-phone-off:before {
  content: "\e935";
}

.ic-eye:before {
  content: "\e936";
}

.ic-phone:before {
  content: "\e937";
}

.ic-octagon:before {
  content: "\e938";
}

.ic-clock:before {
  content: "\e939";
}

.ic-command:before {
  content: "\e93a";
}

.ic-delete:before {
  content: "\e93b";
}

.ic-file-plus:before {
  content: "\e93c";
}

.ic-skip-back:before {
  content: "\e93d";
}

.ic-trash-2:before {
  content: "\e93e";
}

.ic-cloud-rain:before {
  content: "\e93f";
}

.ic-credit-card:before {
  content: "\e940";
}

.ic-plus-circle:before {
  content: "\e941";
}

.ic-unlock:before {
  content: "\e943";
}

.ic-upload-cloud:before {
  content: "\e944";
}

.ic-chevrons-up:before {
  content: "\e945";
}

.ic-alert-circle:before {
  content: "\e946";
}

.ic-anchor:before {
  content: "\e947";
}

.ic-edit:before {
  content: "\e948";
}

.ic-message-square:before {
  content: "\e949";
}

.ic-sun:before {
  content: "\e94a";
}

.ic-cloud-off:before {
  content: "\e94b";
}

.ic-star:before {
  content: "\e94c";
}

.ic-corner-left-down:before {
  content: "\e94d";
}

.ic-trash:before {
  content: "\e94e";
}

.ic-feather:before {
  content: "\e94f";
}

.ic-at-sign:before {
  content: "\e950";
}

.ic-life-buoy:before {
  content: "\e951";
}

.ic-arrow-up-right:before {
  content: "\e952";
}

.ic-printer:before {
  content: "\e953";
}

.ic-link-2:before {
  content: "\e954";
}

.ic-camera:before {
  content: "\e955";
}

.ic-facebook:before {
  content: "\e956";
}

.ic-speaker:before {
  content: "\e957";
}

.ic-phone-call:before {
  content: "\e958";
}

.ic-play:before {
  content: "\e959";
}

.ic-square:before {
  content: "\e95a";
}

.ic-sidebar:before {
  content: "\e95b";
}

.ic-percent:before {
  content: "\e95c";
}

.ic-thumbs-up:before {
  content: "\e95d";
}

.ic-box:before {
  content: "\e95e";
}

.ic-zoom-out:before {
  content: "\e95f";
}

.ic-award:before {
  content: "\e960";
}

.ic-x-square:before {
  content: "\e961";
}

.ic-fast-forward:before {
  content: "\e962";
}

.ic-cloud-lightning:before {
  content: "\e963";
}

.ic-navigation:before {
  content: "\e964";
}

.ic-save:before {
  content: "\e965";
}

.ic-sunset:before {
  content: "\e966";
}

.ic-check-circle:before {
  content: "\e967";
}

.ic-maximize-2:before {
  content: "\e968";
}

.ic-image:before {
  content: "\e969";
}

.ic-align-right:before {
  content: "\e96a";
}

.ic-burger:before {
  content: "\e96b";
}

.ic-zoom-in:before {
  content: "\e96c";
}

.ic-copy:before {
  content: "\e96d";
}

.ic-mic:before {
  content: "\e96e";
}

.ic-refresh-ccw:before {
  content: "\e96f";
}

.ic-loader:before {
  content: "\e970";
}

.ic-user-x:before {
  content: "\e971";
}

.ic-info:before {
  content: "\e972";
}

.ic-watch:before {
  content: "\e973";
}

.ic-corner-up-left:before {
  content: "\e974";
}

.ic-wifi:before {
  content: "\e975";
}

.ic-headphones:before {
  content: "\e976";
}

.ic-pie-chart:before {
  content: "\e977";
}

.ic-bluetooth:before {
  content: "\e978";
}

.ic-rotate-ccw:before {
  content: "\e979";
}

.ic-check:before {
  content: "\e97a";
}

.ic-plus:before {
  content: "\e97b";
}

.ic-share-2:before {
  content: "\e97c";
}

.ic-hash:before {
  content: "\e97d";
}

.ic-cpu:before {
  content: "\e97e";
}

.ic-disc:before {
  content: "\e97f";
}

.ic-battery:before {
  content: "\e980";
}

.ic-eye-off:before {
  content: "\e981";
}

.ic-flag:before {
  content: "\e982";
}

.ic-cast:before {
  content: "\e983";
}

.ic-thermometer:before {
  content: "\e984";
}

.ic-bell:before {
  content: "\e985";
}

.ic-user-minus:before {
  content: "\e986";
}

.ic-book:before {
  content: "\e987";
}

.ic-radio:before {
  content: "\e988";
}

.ic-slash:before {
  content: "\e989";
}

.ic-layers:before {
  content: "\e98a";
}

.ic-battery-charging:before {
  content: "\e98b";
}

.ic-user-plus:before {
  content: "\e98c";
}

.ic-volume:before {
  content: "\e98d";
}

.ic-skip-forward:before {
  content: "\e98e";
}

.ic-server:before {
  content: "\e98f";
}

.ic-corner-right-down:before {
  content: "\e990";
}

.ic-phone-forwarded:before {
  content: "\e991";
}

.ic-shield:before {
  content: "\e992";
}

.ic-mail:before {
  content: "\e993";
}

.ic-pocket:before {
  content: "\e994";
}

.ic-phone-outgoing:before {
  content: "\e995";
}

.ic-type:before {
  content: "\e996";
}

.ic-thumbs-down:before {
  content: "\e997";
}

.ic-cloud-snow:before {
  content: "\e998";
}

.ic-settings:before {
  content: "\e999";
}

.ic-minus-square:before {
  content: "\e99a";
}

.ic-minimize:before {
  content: "\e99b";
}

.ic-bar-chart-2:before {
  content: "\e99c";
}

.ic-log-out:before {
  content: "\e99d";
}

.ic-stop-circle:before {
  content: "\e99e";
}

.ic-aperture:before {
  content: "\e99f";
}

.ic-arrow-right:before, .glyphicon-arrow-right:before {
  content: "\e9a0";
}

.ic-corner-down-right:before {
  content: "\e9a1";
}

.ic-video-off:before {
  content: "\e9a2";
}

.ic-link:before {
  content: "\e9a3";
}

.ic-instagram:before {
  content: "\e9a4";
}

.ic-package:before {
  content: "\e9a5";
}

.ic-clipboard:before {
  content: "\e9a6";
}

.ic-chevron-right:before {
  content: "\e9a7";
}

.ic-refresh-cw:before {
  content: "\e9a8";
}

.ic-cloud-drizzle:before {
  content: "\e9a9";
}

.ic-log-in:before {
  content: "\e9aa";
}

.ic-lock:before {
  content: "\e9ab";
}

.ic-bar-chart:before {
  content: "\e9ac";
}

.ic-x:before {
  content: "\e9ad";
}

.ic-droplet:before {
  content: "\e9ae";
}

.ic-file-text:before {
  content: "\e9af";
}

.ic-arrow-down-left:before {
  content: "\e9b0";
}

.ic-chevron-up:before {
  content: "\e9b1";
}

.ic-maximize:before {
  content: "\e9b2";
}

.ic-move:before {
  content: "\e9b3";
}

.ic-voicemail:before {
  content: "\e9b4";
}

.ic-message-circle:before {
  content: "\e9b5";
}

.ic-file:before {
  content: "\e9b6";
}

.ic-toggle-left:before {
  content: "\e9b7";
}

.ic-camera-off:before {
  content: "\e9b8";
}

.ic-power:before {
  content: "\e9b9";
}

.ic-plus-square:before {
  content: "\e9ba";
}

.ic-align-justify:before {
  content: "\e9bb";
}

.ic-inbox:before {
  content: "\e9bc";
}

.ic-map:before {
  content: "\e9bd";
}

.ic-rotate-cw:before {
  content: "\e9be";
}

.ic-briefcase:before {
  content: "\e9bf";
}

.ic-tag:before {
  content: "\e9c0";
}

.ic-github:before {
  content: "\e9c1";
}

.ic-chevron-down:before {
  content: "\e9c2";
}

.ic-arrow-up-left:before {
  content: "\e9c3";
}

.ic-more-horizontal:before {
  content: "\e9c4";
}

.ic-smartphone:before {
  content: "\e9c5";
}

.ic-music:before {
  content: "\e9c6";
}

.ic-home:before {
  content: "\e9c7";
}

.ic-corner-left-up:before {
  content: "\e9c8";
}

.ic-edit-2:before {
  content: "\e9c9";
}

.ic-phone-missed:before {
  content: "\e9ca";
}

.ic-circle:before {
  content: "\e9cb";
}

.ic-x-circle:before {
  content: "\e9cc";
}

.ic-file-minus:before {
  content: "\e9cd";
}

.ic-umbrella:before {
  content: "\e9ce";
}

.ic-toggle-right:before {
  content: "\e9cf";
}

.ic-sunrise:before {
  content: "\e9d0";
}

.ic-volume-1:before {
  content: "\e9d1";
}

.ic-edit-3:before {
  content: "\e9d2";
}

.ic-repeat:before {
  content: "\e9d3";
}

.ic-airplay:before {
  content: "\e9d4";
}

.ic-crosshair:before {
  content: "\e9d5";
}

.ic-play-circle:before {
  content: "\e9d6";
}

.ic-minimize-2:before {
  content: "\e9d7";
}

.ic-scissors:before {
  content: "\e9d8";
}

.ic-target:before {
  content: "\e9d9";
}

.ic-corner-up-right:before {
  content: "\e9da";
}

.ic-wind:before {
  content: "\e9db";
}

.ic-chevrons-down:before {
  content: "\e9dc";
}

.ic-chevrons-right:before {
  content: "\e9dd";
}

.ic-corner-right-up:before {
  content: "\e9de";
}

.ic-arrow-down:before {
  content: "\e9df";
}

.ic-check-square:before {
  content: "\e9e0";
}

.ic-pause-circle:before {
  content: "\e9e1";
}

.ic-triangle:before {
  content: "\e9e2";
}

.ic-grid:before {
  content: "\e9e3";
}

.ic-time:before {
  content: "\e900";
}

.ic-download:before {
  content: "\e901";
}

.ic-pin:before {
  content: "\e902";
}

.ic-calendar:before {
  content: "\e903";
}

.ic-search:before {
  content: "\e904";
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html[lang^=ar],
body[lang^=ar] {
  direction: rtl;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

body {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #2c3e50;
  background-color: #f3f3f3;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
}

#app {
  margin: 0 auto;
}

p {
  margin-top: 0;
  margin-bottom: 1.75rem;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

/* clears Safari autocompletion icons */
input::-webkit-credentials-auto-fill-button {
  position: absolute;
  width: 1px;
  height: 1px;
  background-color: transparent;
  visibility: hidden;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #9b9b9b;
  text-decoration: none;
  cursor: pointer;
}
a:hover, a:focus {
  color: #c03303;
  text-decoration: underline;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

hr {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 0;
  border-top: 1px solid white;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

small,
.small {
  font-size: 12px;
  font-size: 0.75rem;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #a0b5cb;
}

.form-description {
  margin: 0 0 24px;
  display: block;
}

.form-group {
  margin: 0 0 24px;
}

label {
  font-size: 0.8rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.4rem 0;
  font-size: 1rem;
  line-height: 1.5;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;
  border-bottom: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
}
.form-control.is-invalid {
  border-color: #dc3545;
}
.form-control.is-invalid ~ .invalid-feedback {
  display: block;
}
.form-control:focus {
  outline: none;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-check label {
  font-weight: normal;
  cursor: pointer;
}
.form-check label span {
  display: block;
  margin-left: 30px;
}
.form-check label input[type=checkbox],
.form-check label input[type=radio] {
  float: left;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
}

.is-invalid > .invalid-feedback {
  display: block;
}

.form-actions::after {
  content: "";
  display: table;
  clear: both;
}

.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.6rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: opacity 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-overflow: ellipsis;
  overflow: hidden;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:hover {
  text-decoration: none;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
.btn.btn-block {
  max-width: 280px;
  margin: 0 auto 10px;
}
.btn.btn-tertiary, .btn.back {
  background-color: transparent;
  color: #9b9b9b;
  border-color: transparent;
}
.btn.back {
  padding-left: 0;
  padding-right: 0;
}

#app.ready .card-panel {
  display: block;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.error, .card-panel {
  max-width: 570px;
  margin: 0 auto;
}

.card-panel {
  display: none;
  position: relative;
  padding: 32px;
}
.card-panel.with-banner {
  padding-top: 85px;
}
.card-panel h1,
.card-panel h2 {
  text-align: center;
  font-weight: 700;
}
.card-panel .webinar-info {
  margin-top: 16px;
}

.banner {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px 10px 0 0;
}
.banner img {
  width: inherit;
  border-radius: inherit;
}

.outer-box {
  display: flex;
}
.outer-box > * {
  flex: 1 1 100%;
}
.outer-box > *.logins {
  max-width: 250px;
}

.intros {
  border-bottom: 1px solid #efefef;
  padding: 0 20px 20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
}

.logins {
  margin: 0 auto;
}
.logins .btn {
  width: 100%;
  margin-bottom: 10px;
}

.service-links {
  margin: 10px -5px;
  text-align: right;
}
.service-links a {
  display: inline-block;
  padding: 0 5px;
  cursor: pointer;
}

.error {
  border-radius: 10px;
  background-color: #ffcccc;
  border-color: #ff8080;
  display: none;
  padding: 30px 40px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
}
.error.callback {
  padding: 8px;
  padding-bottom: 24px;
  margin-top: 4px;
}
.error.in {
  display: block;
}
.error p {
  margin: 0;
}
.error.reason {
  display: block;
  margin-bottom: 20px;
}

.search-toggler {
  float: right;
  vertical-align: middle;
  line-height: 1rem;
  padding: 6px;
  font-size: 1.25rem;
  margin: 0 6px;
}

.account {
  margin: 0 0 0 6px;
  position: relative;
}
.account i.icon-user {
  position: relative;
  cursor: pointer;
  z-index: 5;
  display: inline-block;
  line-height: 1;
  font-size: 1.25rem;
  padding: 6px;
}

.menu {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  border: 1px solid #efefef;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
  line-height: 1;
  list-style-type: none;
  margin: 40px 0 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  transform: translateY(-200%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.menu.in {
  opacity: 1;
  transform: translateY(0);
  z-index: 10;
}
.menu li a,
.menu li span.email {
  display: block;
  padding: 10px 15px;
  min-width: 200px;
}
.menu a {
  cursor: pointer;
}

.events {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 20px;
}

.event {
  flex: 0 0 33.33%;
  cursor: pointer;
  padding: 10px;
}
.event.past .evt-icon:before {
  position: absolute;
  content: "";
  display: block;
  background-color: rgba(155, 155, 155, 0.5);
  height: 100%;
  width: 100%;
}
.event.past .icon-time {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 36px;
  margin-top: -18px;
  margin-left: -18px;
}
.event .inner {
  height: 130px;
  overflow: hidden;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.event .inner:hover {
  transform: scale(1.02);
}
.event .evt-icon {
  width: 130px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
}
.event .evt-icon:before,
.event .evt-icon .icon {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.event .evt-icon .icon {
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/event_placeholder.png");
}
.event .details {
  margin-left: 130px;
}
.event .name {
  height: 44px;
  max-height: 44px;
  overflow: hidden;
}
.event span {
  display: block;
}
.event span.text {
  display: inline;
}
.event span.title {
  height: 44px;
  margin-bottom: 10px;
  display: table-cell;
  line-height: 1.4;
  vertical-align: middle;
  font-weight: bold;
}
.event span.dates, .event span.venue {
  font-size: 0.75rem;
  line-height: 2;
}
.event span i {
  margin-right: 10px;
}

.legals {
  padding: 20px;
  max-height: 100%;
  overflow: auto;
}

.legals a,
.legal-acknowledgements a {
  color: #000;
  font-weight: bold;
}

.back {
  float: left;
}

.invitations {
  padding-top: 125px;
}
.invitations h5 {
  margin: 24px 0 16px;
  font-weight: normal;
  color: #9b9b9b;
}
.invitations .empty {
  margin-top: 24px;
  text-align: center;
}
.invitations .empty svg {
  max-width: 348px;
}
.invitations .empty p {
  margin-top: 24px;
  font-weight: bold;
}

header.main {
  background-color: #fff;
  box-shadow: 0 -10px 20px 10px rgba(0, 0, 0, 0.2);
  padding: 35px 0;
  color: #9b9b9b;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
}
header.main .container > * {
  display: inline-block;
  vertical-align: middle;
}
header.main .container > *.search-toggler {
  display: none;
}
header.main h1 {
  margin: 0;
  color: #4a4a4a;
}
header.main .account {
  float: right;
}

.install {
  text-align: center;
}
.install .frame {
  display: block;
  width: 240px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 10px auto;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.install .frame.in {
  opacity: 1;
}
.install .frame.out {
  display: none;
}
.install .frame img {
  width: 130px;
  margin: 36px;
}
.install .install-button {
  display: block;
  max-width: 250px;
  margin: 10px auto;
}
.install .badges {
  display: inline-block;
}
.install .badges a {
  display: inline-block;
  max-width: 135px;
  margin: 0 20px;
}
.install .badges a.app-store-link img {
  margin: 6%;
  width: 88%;
}

.pass-group .form-group {
  position: relative;
}

.password-score {
  position: absolute;
  right: 10px;
  top: 30px;
  font-weight: bold;
}
.password-score .score-tab[data-score="0"] {
  color: #d20e26;
}
.password-score .score-tab[data-score="1"] {
  color: #d20e26;
}
.password-score .score-tab[data-score="2"] {
  color: #f6af3a;
}
.password-score .score-tab[data-score="3"] {
  color: #80d325;
}
.password-score .score-tab[data-score="4"] {
  color: #80d325;
}

.login-methods {
  text-align: center;
}
.login-methods .btn {
  display: block;
  margin-bottom: 20px;
}

.email-sent {
  text-align: center;
}

.welcome {
  text-align: center;
}
.welcome h2 {
  margin-top: 0;
}
.welcome img.splash {
  max-width: 228px;
  margin: 50px 0;
}
.welcome .btn {
  margin-bottom: 30px;
}

ul.tabs {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
ul.tabs li {
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  padding: 7px 12px;
  margin: 0 5px;
  line-height: 1;
}

.search-bar {
  margin: 0 20px;
}
.search-bar .form-group {
  margin: 0;
  position: relative;
}
.search-bar input {
  border: 0;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 4px;
  min-width: 270px;
}
.search-bar input:focus + i.icon-search {
  opacity: 0;
}
.search-bar i.ic-search {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0.5s;
}
.search-bar .clear {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 36px;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
}
.search-bar .clear.in {
  opacity: 1;
  cursor: pointer;
}

footer.links {
  margin-top: 15px;
  text-align: center;
}
footer.links::after {
  content: "";
  display: table;
  clear: both;
}
footer.links a {
  color: #9b9b9b;
}
footer.links span {
  color: #9b9b9b;
  padding-left: 3px;
  padding-right: 3px;
}

@media (max-width: 991.98px) {
  h1 {
    font-size: 1.25rem;
  }
  .container {
    max-width: 738px;
  }
  header.main {
    padding: 30px 0 24px;
  }
  header.main .container > .search-toggler,
  header.main .container > .account {
    display: inline-block;
  }
  header.main .container > .search-bar {
    display: block;
    height: 0;
    overflow: hidden;
    margin: 0;
    clear: both;
    transition: height 0.2s ease-in-out, margin 0.2s ease-in-out;
  }
  header.main .container > .search-bar.in {
    height: 40px;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  header.main .container > .search-bar .icon-search {
    display: none;
  }
  header.main .container > ul.tabs {
    display: block;
    margin-top: 10px;
    margin-left: -5px;
  }
  .invitations {
    padding-top: 129px;
  }
  .event .inner {
    padding: 10px;
  }
  .event .evt-icon {
    width: 108px;
  }
  .event .details {
    margin-left: 108px;
  }
}
@media (max-width: 767px) {
  body {
    background-color: #fff;
    padding-top: 0;
  }
  .container {
    max-width: none;
    padding-left: 15px;
    padding-right: 15px;
  }
  .event {
    flex: 0 0 100%;
  }
  .card-panel {
    padding: 16px;
  }
  .card-panel.with-banner {
    padding-top: 65px;
  }
}
@media (min-width: 768px) {
  .event {
    flex: 0 0 50%;
  }
  .card-panel {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 952px;
  }
  .search-bar input {
    min-width: 200px;
  }
  .account {
    margin-top: 6px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
  .event {
    flex: 0 0 33.33%;
  }
  .search-bar input {
    min-width: 270px;
  }
}
.public-login .expandable-text {
  font-size: 14px;
  line-height: 22px;
  color: #18191a;
  margin: 12px 0 24px;
  max-width: calc(570px - 2 * 32px);
}
.public-login .expandable-text .read-more {
  text-align: right;
}
.public-login .expandable-text .text {
  overflow: hidden;
  overflow-wrap: break-word;
}
.public-login .expandable-text .text .ql-align-center {
  text-align: center;
}
.public-login .expandable-text .text .ql-align-left {
  text-align: left;
}
.public-login .expandable-text .text .ql-align-right {
  text-align: right;
}
.public-login .expandable-text .text .ql-align-justify {
  text-align: justify;
}
.public-login .expandable-text .text .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.public-login .expandable-text .text li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.public-login .expandable-text .text .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.public-login .expandable-text .text li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.public-login .expandable-text .text .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.public-login .expandable-text .text li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.public-login .expandable-text .text .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.public-login .expandable-text .text li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.public-login .expandable-text .text .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.public-login .expandable-text .text li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.public-login .expandable-text .text .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.public-login .expandable-text .text li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.public-login .expandable-text .text .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.public-login .expandable-text .text li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.public-login .expandable-text .text .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.public-login .expandable-text .text li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.public-login .expandable-text .text .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.public-login .expandable-text .text li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.public-login .expandable-text .text .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.public-login .expandable-text .text li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.public-login .expandable-text .text .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.public-login .expandable-text .text li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.public-login .expandable-text .text .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.public-login .expandable-text .text li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.public-login .expandable-text .text .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.public-login .expandable-text .text li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.public-login .expandable-text .text .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.public-login .expandable-text .text li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.public-login .expandable-text .text .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.public-login .expandable-text .text li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.public-login .expandable-text .text .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.public-login .expandable-text .text li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.public-login .expandable-text .text .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.public-login .expandable-text .text li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.public-login .expandable-text .text .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.public-login .expandable-text .text li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.public-login .expandable-text .text ol.ql-list {
  list-style-type: none;
}
.public-login .expandable-text .text ol.ql-list li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.public-login .expandable-text .text ol.ql-list li::before {
  content: counter(list-0, decimal) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-1 {
  counter-increment: list-1;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-1::before {
  content: counter(list-1, lower-alpha) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-1 {
  counter-reset: slice(list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9, 2, 9);
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-2 {
  counter-increment: list-2;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-2::before {
  content: counter(list-2, lower-roman) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-2 {
  counter-reset: slice(list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9, 3, 9);
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-3 {
  counter-increment: list-3;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-3::before {
  content: counter(list-3, decimal) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-3 {
  counter-reset: slice(list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9, 4, 9);
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-4 {
  counter-increment: list-4;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-4::before {
  content: counter(list-4, lower-alpha) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-4 {
  counter-reset: slice(list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9, 5, 9);
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-5 {
  counter-increment: list-5;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-5::before {
  content: counter(list-5, lower-roman) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-5 {
  counter-reset: slice(list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9, 6, 9);
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-6 {
  counter-increment: list-6;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-6::before {
  content: counter(list-6, decimal) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-6 {
  counter-reset: slice(list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9, 7, 9);
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-7 {
  counter-increment: list-7;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-7::before {
  content: counter(list-7, lower-alpha) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-7 {
  counter-reset: slice(list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9, 8, 9);
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-8 {
  counter-increment: list-8;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-8::before {
  content: counter(list-8, lower-roman) ". ";
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-8 {
  counter-reset: slice(list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9, 9, 9);
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-9 {
  counter-increment: list-9;
}
.public-login .expandable-text .text ol.ql-list li.ql-indent-9::before {
  content: counter(list-9, decimal) ". ";
}
.public-login .expandable-text .text .ql-list {
  list-style-type: none;
}
.public-login .expandable-text .text .ql-list > li::before {
  content: "•";
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.public-login .expandable-text .text .ql-list li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.public-login .expandable-text .text blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eeeeee;
}

.webinar-response .calendar-button .btn {
  margin-top: 20px;
}
.webinar-response .calendar-button .menu {
  right: calc(50% + 36px);
}

.calendar-button {
  position: relative;
}
.calendar-button button.btn {
  height: 36px;
  border-radius: 8px;
  padding: 8px;
  border: 0;
  background-color: #f7f7f7;
  color: #343a40;
  text-align: center;
  font-size: 14px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.calendar-button button.btn:hover, .calendar-button button.btn.in {
  background-color: #dddddd;
  color: #343a40;
}
.calendar-button button.btn i {
  font-size: 20px;
  vertical-align: top;
  margin-right: 8px;
}
.calendar-button button.btn.single i {
  margin-right: 0;
}
.calendar-button button.btn span {
  margin-left: 8px;
}
.calendar-button .menu {
  display: block;
  margin: 40px -170px 0;
  transform: none;
  border-radius: 8px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.calendar-button .menu.fade-enter-active, .calendar-button .menu.fade-leave-active {
  opacity: 1;
}
.calendar-button .menu.fade-enter, .calendar-button .menu.fade-leave-to {
  opacity: 0;
}
.calendar-button .menu li {
  min-width: 200px;
}
.calendar-button .menu li a {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 4px;
}
.calendar-button .menu li a:focus, .calendar-button .menu li a:hover {
  text-decoration: none;
}
.calendar-button .menu li a:hover {
  background: #f3f3f3;
}
.calendar-button .menu li a i {
  width: 24px;
  height: 24px;
  margin: 3px 0 0 6px;
}
.calendar-button .menu li a i.icon-cal {
  background-image: url("../images/generic_calendar.svg");
}
.calendar-button .menu li a i.icon-gcal {
  background-image: url("../images/google_cal.svg");
}
.calendar-button .menu li a span {
  color: black;
  font-size: 14px;
  padding: 8px;
}

.webinar-info {
  display: flex;
  padding: 16px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.0784313725);
}
.webinar-info .icon > img {
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 8px;
}
.webinar-info .meta {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.webinar-info .meta .info {
  font-weight: 600;
}
.webinar-info .meta .info .title {
  font-size: 20px;
  line-height: 32px;
  color: #18191a;
}
.webinar-info .meta .info .datetime {
  font-size: 14px;
  line-height: 20px;
  color: #7d8082;
}
.webinar-info .meta .info .datetime .timezone {
  font-weight: 400;
  margin-left: 5px;
}

.v--modal-overlay .v--modal-box {
  transform: translateY(-50%);
  top: 50% !important;
  position: absolute;
  max-height: 75vh;
  overflow: auto;
}

.cookies-notification {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(100%);
  transition: transform 0.2s ease-in-out;
  z-index: 500;
}
.cookies-notification.in {
  transform: translateY(0);
}
.cookies-notification p {
  text-align: center;
  color: #fff;
}
.cookies-notification p:last-of-type {
  margin-bottom: 0;
}
.cookies-notification button {
  margin: 0 10px;
}
.cookies-notification a {
  text-decoration: underline;
  color: #fff;
}