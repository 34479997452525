[class^="ic-"], [class*=" ic-"], .glyphicon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'fonticon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-loader {
    display: inline-block;
}

.glyphicon-arrow-right {
    @extend .ic-arrow-right;
}

.glyphicon-arrow-left {
    @extend .ic-arrow-left;
}

.ic-volume-2:before { content: "\e942"; }
.ic-compass:before { content: "\e9e4"; }
.ic-user:before { content: "\e9e5"; }
.ic-trending-up:before { content: "\e905"; }
.ic-heart:before { content: "\e906"; }
.ic-align-left:before { content: "\e907"; }
.ic-layout:before { content: "\e908"; }
.ic-moon:before { content: "\e909"; }
.ic-film:before { content: "\e90a"; }
.ic-chevron-left:before { content: "\e90b"; }
.ic-navigation-2:before { content: "\e90c"; }
.ic-minus:before { content: "\e90d"; }
.ic-monitor:before { content: "\e90e"; }
.ic-corner-down-left:before { content: "\e90f"; }
.ic-users:before { content: "\e910"; }
.ic-folder:before { content: "\e911"; }
.ic-chrome:before { content: "\e912"; }
.ic-menu:before { content: "\e913"; }
.ic-alert-octagon:before { content: "\e914"; }
.ic-tablet:before { content: "\e915"; }
.ic-user-check:before { content: "\e916"; }
.ic-alert-triangle:before { content: "\e917"; }
.ic-bookmark:before { content: "\e918"; }
.ic-pause:before { content: "\e919"; }
.ic-trending-down:before { content: "\e91a"; }
.ic-upload:before { content: "\e91b"; }
.ic-rewind:before { content: "\e91c"; }
.ic-shuffle:before { content: "\e91d"; }
.ic-download-cloud:before { content: "\e91e"; }
.ic-cloud:before { content: "\e91f"; }
.ic-slack:before { content: "\e920"; }
.ic-volume-x:before { content: "\e921"; }
.ic-arrow-down-right:before { content: "\e922"; }
.ic-minus-circle:before { content: "\e923"; }
.ic-align-center:before { content: "\e924"; }
.ic-arrow-left:before { content: "\e925"; }
.ic-globe:before { content: "\e926"; }
.ic-chevrons-left:before { content: "\e927"; }
.ic-phone-incoming:before { content: "\e928"; }
.ic-filter:before { content: "\e929"; }
.ic-map-pin:before { content: "\e92a"; }
.ic-twitter:before { content: "\e92b"; }
.ic-activity:before { content: "\e92c"; }
.ic-video:before { content: "\e92d"; }
.ic-bell-off:before { content: "\e92e"; }
.ic-arrow-up:before { content: "\e92f"; }
.ic-share:before { content: "\e930"; }
.ic-mic-off:before { content: "\e931"; }
.ic-more-vertical:before { content: "\e932"; }
.ic-zap:before { content: "\e933"; }
.ic-external-link:before { content: "\e934"; }
.ic-phone-off:before { content: "\e935"; }
.ic-eye:before { content: "\e936"; }
.ic-phone:before { content: "\e937"; }
.ic-octagon:before { content: "\e938"; }
.ic-clock:before { content: "\e939"; }
.ic-command:before { content: "\e93a"; }
.ic-delete:before { content: "\e93b"; }
.ic-file-plus:before { content: "\e93c"; }
.ic-skip-back:before { content: "\e93d"; }
.ic-trash-2:before { content: "\e93e"; }
.ic-cloud-rain:before { content: "\e93f"; }
.ic-credit-card:before { content: "\e940"; }
.ic-plus-circle:before { content: "\e941"; }
.ic-unlock:before { content: "\e943"; }
.ic-upload-cloud:before { content: "\e944"; }
.ic-chevrons-up:before { content: "\e945"; }
.ic-alert-circle:before { content: "\e946"; }
.ic-anchor:before { content: "\e947"; }
.ic-edit:before { content: "\e948"; }
.ic-message-square:before { content: "\e949"; }
.ic-sun:before { content: "\e94a"; }
.ic-cloud-off:before { content: "\e94b"; }
.ic-star:before { content: "\e94c"; }
.ic-corner-left-down:before { content: "\e94d"; }
.ic-trash:before { content: "\e94e"; }
.ic-feather:before { content: "\e94f"; }
.ic-at-sign:before { content: "\e950"; }
.ic-life-buoy:before { content: "\e951"; }
.ic-arrow-up-right:before { content: "\e952"; }
.ic-printer:before { content: "\e953"; }
.ic-link-2:before { content: "\e954"; }
.ic-camera:before { content: "\e955"; }
.ic-facebook:before { content: "\e956"; }
.ic-speaker:before { content: "\e957"; }
.ic-phone-call:before { content: "\e958"; }
.ic-play:before { content: "\e959"; }
.ic-square:before { content: "\e95a"; }
.ic-sidebar:before { content: "\e95b"; }
.ic-percent:before { content: "\e95c"; }
.ic-thumbs-up:before { content: "\e95d"; }
.ic-box:before { content: "\e95e"; }
.ic-zoom-out:before { content: "\e95f"; }
.ic-award:before { content: "\e960"; }
.ic-x-square:before { content: "\e961"; }
.ic-fast-forward:before { content: "\e962"; }
.ic-cloud-lightning:before { content: "\e963"; }
.ic-navigation:before { content: "\e964"; }
.ic-save:before { content: "\e965"; }
.ic-sunset:before { content: "\e966"; }
.ic-check-circle:before { content: "\e967"; }
.ic-maximize-2:before { content: "\e968"; }
.ic-image:before { content: "\e969"; }
.ic-align-right:before { content: "\e96a"; }
.ic-burger:before { content: "\e96b"; }
.ic-zoom-in:before { content: "\e96c"; }
.ic-copy:before { content: "\e96d"; }
.ic-mic:before { content: "\e96e"; }
.ic-refresh-ccw:before { content: "\e96f"; }
.ic-loader:before { content: "\e970"; }
.ic-user-x:before { content: "\e971"; }
.ic-info:before { content: "\e972"; }
.ic-watch:before { content: "\e973"; }
.ic-corner-up-left:before { content: "\e974"; }
.ic-wifi:before { content: "\e975"; }
.ic-headphones:before { content: "\e976"; }
.ic-pie-chart:before { content: "\e977"; }
.ic-bluetooth:before { content: "\e978"; }
.ic-rotate-ccw:before { content: "\e979"; }
.ic-check:before { content: "\e97a"; }
.ic-plus:before { content: "\e97b"; }
.ic-share-2:before { content: "\e97c"; }
.ic-hash:before { content: "\e97d"; }
.ic-cpu:before { content: "\e97e"; }
.ic-disc:before { content: "\e97f"; }
.ic-battery:before { content: "\e980"; }
.ic-eye-off:before { content: "\e981"; }
.ic-flag:before { content: "\e982"; }
.ic-cast:before { content: "\e983"; }
.ic-thermometer:before { content: "\e984"; }
.ic-bell:before { content: "\e985"; }
.ic-user-minus:before { content: "\e986"; }
.ic-book:before { content: "\e987"; }
.ic-radio:before { content: "\e988"; }
.ic-slash:before { content: "\e989"; }
.ic-layers:before { content: "\e98a"; }
.ic-battery-charging:before { content: "\e98b"; }
.ic-user-plus:before { content: "\e98c"; }
.ic-volume:before { content: "\e98d"; }
.ic-skip-forward:before { content: "\e98e"; }
.ic-server:before { content: "\e98f"; }
.ic-corner-right-down:before { content: "\e990"; }
.ic-phone-forwarded:before { content: "\e991"; }
.ic-shield:before { content: "\e992"; }
.ic-mail:before { content: "\e993"; }
.ic-pocket:before { content: "\e994"; }
.ic-phone-outgoing:before { content: "\e995"; }
.ic-type:before { content: "\e996"; }
.ic-thumbs-down:before { content: "\e997"; }
.ic-cloud-snow:before { content: "\e998"; }
.ic-settings:before { content: "\e999"; }
.ic-minus-square:before { content: "\e99a"; }
.ic-minimize:before { content: "\e99b"; }
.ic-bar-chart-2:before { content: "\e99c"; }
.ic-log-out:before { content: "\e99d"; }
.ic-stop-circle:before { content: "\e99e"; }
.ic-aperture:before { content: "\e99f"; }
.ic-arrow-right:before { content: "\e9a0"; }
.ic-corner-down-right:before { content: "\e9a1"; }
.ic-video-off:before { content: "\e9a2"; }
.ic-link:before { content: "\e9a3"; }
.ic-instagram:before { content: "\e9a4"; }
.ic-package:before { content: "\e9a5"; }
.ic-clipboard:before { content: "\e9a6"; }
.ic-chevron-right:before { content: "\e9a7"; }
.ic-refresh-cw:before { content: "\e9a8"; }
.ic-cloud-drizzle:before { content: "\e9a9"; }
.ic-log-in:before { content: "\e9aa"; }
.ic-lock:before { content: "\e9ab"; }
.ic-bar-chart:before { content: "\e9ac"; }
.ic-x:before { content: "\e9ad"; }
.ic-droplet:before { content: "\e9ae"; }
.ic-file-text:before { content: "\e9af"; }
.ic-arrow-down-left:before { content: "\e9b0"; }
.ic-chevron-up:before { content: "\e9b1"; }
.ic-maximize:before { content: "\e9b2"; }
.ic-move:before { content: "\e9b3"; }
.ic-voicemail:before { content: "\e9b4"; }
.ic-message-circle:before { content: "\e9b5"; }
.ic-file:before { content: "\e9b6"; }
.ic-toggle-left:before { content: "\e9b7"; }
.ic-camera-off:before { content: "\e9b8"; }
.ic-power:before { content: "\e9b9"; }
.ic-plus-square:before { content: "\e9ba"; }
.ic-align-justify:before { content: "\e9bb"; }
.ic-inbox:before { content: "\e9bc"; }
.ic-map:before { content: "\e9bd"; }
.ic-rotate-cw:before { content: "\e9be"; }
.ic-briefcase:before { content: "\e9bf"; }
.ic-tag:before { content: "\e9c0"; }
.ic-github:before { content: "\e9c1"; }
.ic-chevron-down:before { content: "\e9c2"; }
.ic-arrow-up-left:before { content: "\e9c3"; }
.ic-more-horizontal:before { content: "\e9c4"; }
.ic-smartphone:before { content: "\e9c5"; }
.ic-music:before { content: "\e9c6"; }
.ic-home:before { content: "\e9c7"; }
.ic-corner-left-up:before { content: "\e9c8"; }
.ic-edit-2:before { content: "\e9c9"; }
.ic-phone-missed:before { content: "\e9ca"; }
.ic-circle:before { content: "\e9cb"; }
.ic-x-circle:before { content: "\e9cc"; }
.ic-file-minus:before { content: "\e9cd"; }
.ic-umbrella:before { content: "\e9ce"; }
.ic-toggle-right:before { content: "\e9cf"; }
.ic-sunrise:before { content: "\e9d0"; }
.ic-volume-1:before { content: "\e9d1"; }
.ic-edit-3:before { content: "\e9d2"; }
.ic-repeat:before { content: "\e9d3"; }
.ic-airplay:before { content: "\e9d4"; }
.ic-crosshair:before { content: "\e9d5"; }
.ic-play-circle:before { content: "\e9d6"; }
.ic-minimize-2:before { content: "\e9d7"; }
.ic-scissors:before { content: "\e9d8"; }
.ic-target:before { content: "\e9d9"; }
.ic-corner-up-right:before { content: "\e9da"; }
.ic-wind:before { content: "\e9db"; }
.ic-chevrons-down:before { content: "\e9dc"; }
.ic-chevrons-right:before { content: "\e9dd"; }
.ic-corner-right-up:before { content: "\e9de"; }
.ic-arrow-down:before { content: "\e9df"; }
.ic-check-square:before { content: "\e9e0"; }
.ic-pause-circle:before { content: "\e9e1"; }
.ic-triangle:before { content: "\e9e2"; }
.ic-grid:before { content: "\e9e3"; }
.ic-time:before { content: "\e900"; }
.ic-download:before { content: "\e901"; }
.ic-pin:before { content: "\e902"; }
.ic-calendar:before { content: "\e903"; }
.ic-search:before { content: "\e904"; }
